<nb-layout>
	<nb-layout-column class="login_layout">
		<div class="login_layout_1f row">
			<div class="col-12 col-sm-12 col-md-6 .col-lg-6 login_left">
				<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login_form">
					<img src="../../assets/images/logo_TU.png">
					<h3>Orthopaedics <span>Schedule</span></h3>
					<div class="form-group">
						<input type="text" formControlName="username" class="form-control" placeholder="ชื่อผู้ใช้งาน"
							[ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
						<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
							<div *ngIf="f.username.errors.required">ชื่อผู้ใช้งาน is required</div>
						</div>
					</div>
					<div class="form-group">
						<input type="password" formControlName="password" class="form-control" placeholder="รหัสผ่าน"
							[ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
						<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
							<div *ngIf="f.password.errors.required">รหัสผ่าน is required</div>
						</div>
					</div>
					<div class="form-group">
						<button *ngIf="!loading" [disabled]="loading" class="btn login_btn">เข้าสู่ระบบ</button>
						<img *ngIf="loading" class="login_load pl-3"
							src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
					</div>
					<div class="form-group login_forgot">
						<a>หากลืมรหัสผ่านโปรดติดต่อเจ้าหน้าที่</a>
					</div>
					<div class="brtop"></div>
					<div class="download-group">
						<p style="font-size: 14px;color:#c51c30;font-weight: 600;">TU Orthopaedics <span
								style="color:#3e95b5;">Schedule</span></p>
						<img class="download_2" src="../../assets/images/avvv.svg">
						<div class="row download_3">
							<div class="col-12 col-sm-6 col-md-6 d1">
								<a href="https://play.google.com/store/apps/details?id=com.zigmanice.tu.calendars"
									target="_blank">
									<img src="../../assets/images/Google-play-store-icon.png" style="height:auto">
								</a>
							</div>
							<div class="col-12 col-sm-6 col-md-6 d2">
								<a (click)="transform()" target="_blank" style="cursor: pointer" >
									<img src="../../assets/images/ios.png" style="height: 57px;">
								</a>
							</div>
						</div>

					</div>
				</form>
			</div>
			<div class="col-0 col-sm-12 col-md-6 .col-lg-6 login_right">
			</div>
		</div>

		<router-outlet></router-outlet>

	</nb-layout-column>
</nb-layout>