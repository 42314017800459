import { Component, OnInit, Input, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {formatDate} from '@angular/common'
import { ApiService } from '../../api.service'
import * as queryString from 'query-string';
import * as moment from 'moment';
import { type } from 'os';

@Component({
	selector: 'ngx-dialog.component',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
	public title: string = "";
	public list: any = [];
	public action: string = 'list';
	public currentDetail: any;
	public current_parameter: any;
	public current_date: string;
	public detailFetch;
	public typList: any = {
		"MN-001": "surgery schedule",
		"MN-002": "service schedule",
		"MN-003": "academic schedule",
		"MN-004": "activity schedule",
		"MN-005": "my schedule"
	}
	code  = null;
	constructor(
		protected ref: NbDialogRef<DialogComponent>,
		private authenticationService: ApiService
	) { }

	ngOnInit() { }

	loadList(getDate: Date,code: String): void {
		this.code = code;
		var d = new Date(getDate)
		var day = d.getDate(); // current day
		var m = d.getMonth(); //current month
		var y = d.getFullYear(); //current year
		var start_date = new Date(y, m, day + 1).toISOString().slice(0, 10);
		var nextDay = new Date(start_date);
		nextDay.setDate(d.getDate() + 1);
		var format = 'yyyy-MM-dd';
		var locale = 'en-US';
		var endD = formatDate(nextDay, format, locale);
		this.setTitle(start_date);
		const parameters = this.current_parameter
		parameters.start_date = start_date;
		parameters.end_date = start_date;
		parameters.action_type = 'list';
		this.current_date = start_date;
		this.list = [];
		if(this.code == 'MN-005'){
			delete parameters.code;
			var param = queryString.stringify(parameters)
			this.authenticationService.loadMyScheduleList(param).subscribe(
				data => {
					if (data.statusCode === 200) {
						this.list = data.data;
					}
				},
				error => {
					console.log(error);
					this.list = [];
				});
		}else{
			delete parameters.code;
			var param = queryString.stringify(parameters)
			this.authenticationService.loadScheduleList(param).subscribe(
				data => {
					if (data.statusCode === 200) {
						this.list = data.data;
					}
				},
				error => {
					console.log(error);
					this.list = [];
				});
		}
		
	}

	checkFormatData(val){
		return moment(val, 'YYYY-MM-DD HH:mm').toDate()
	}

	checkDupDate(day1,day2){
		try {
			var a = moment(day1);   
			var b = moment(day2);    
			let diff = a.diff(b) // 86400000  
			if(diff == 0){
				return false
			}
			return true
		} catch (error) {
			return true
		}
		
	}

	mordetail(object) {
		this.action = 'detail';
		const type:string = object.schedule_type;
		var response:string = "";
		this.detailFetch = object;
		this.currentDetail = response;
	}
	backStep() {
		this.action = 'list';
	}

	dismiss() {
		this.ref.close();
	}

	setTitle(text: string): void {
		this.title = new Date(text).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
	}

	previous() {
		var d = new Date(this.current_date)
		var day = d.getDate(); // current day
		var m = d.getMonth(); //current month
		var y = d.getFullYear(); //current year
		var date = new Date(y, m, day).toISOString().slice(0, 10);
		this.loadList(new Date(date),this.code)
		this.setTitle(date)
	}
	next() {
		var d = new Date(this.current_date)
		var day = d.getDate(); // current day
		var m = d.getMonth(); //current month
		var y = d.getFullYear(); //current year
		var date = new Date(y, m, (day + 1) + 1).toISOString().slice(0, 10);
		this.loadList(new Date(date),this.code)
		this.setTitle(date)
	}
	today() {
		var d = new Date()
		var day = d.getDate(); // current day
		var m = d.getMonth(); //current month
		var y = d.getFullYear(); //current year
		var date = new Date(y, m, day + 1).toISOString().slice(0, 10);
		this.loadList(new Date(date),this.code)
		this.setTitle(date)
	}
}
