<nb-layout>
	<nb-layout-header fixed>
		<div class="home_logo">
			<img src="../../assets/images/logo_TU.png" style="width: 260px;">
		</div>
		<!-- <nb-user size="large" class="home_profile" name="{{name}}" title="{{title}}" picture="{{picture}}"
			[nbContextMenu]="items" nbContextMenuTag="my-context-menu">
		</nb-user> -->
		<div class="btn-group home_geoup">
			<div class="home_info" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
				(click)="expandInfo()">
				<img src="{{picture}}">
				<label>{{name}}</label>
			</div>
			<div class="dropdown-menu dropdownInfo" [ngClass]="statusInfo ? 'show' : ''">
				<div class="dropdownInfo_img">
					<img [src]="picture" (error)="onImgError($event)">
					<label>{{name}}</label>
				</div>
				<p style="padding-left: 10px;">{{name}}</p>
				<p style="margin-bottom: 10px;padding-left: 10px;">{{profile.work_position}}</p>
				<p><img src="../../assets/images/mail.png">{{profile.email}}</p>
				<p><img src="../../assets/images/phone.png">{{profile.phone}}</p>
				<p><img src="../../assets/images/fax.png">{{profile.fax}}</p>
				<div class="dropdown-divider"></div>
				<p (click)="logout()" style="cursor: pointer;"><img src="../../assets/images/lock.png"
						style="width: auto;cursor: pointer;">Logout</p>
			</div>
		</div>
	</nb-layout-header>

	<nb-layout-column class="home_layout">
		<div class="col_left">
			<div *ngIf="menuList" class="home_menulist" id="hoomeside" style="display: none">
				<p class="home_title">Orthopaedics <span>Schedule</span></p>
				<div *ngFor="let data of menuList; let i=index">
					<div *ngIf="data.menu_code == surgery_schedule">
						<nb-checkbox (checkedChange)="toggle($event, data.menu_code)"
							[checked]="!checkList[data.menu_code].includes(false)"
							[ngStyle]="{'background-color':data.schedule_color}" class="{{data.menu_code}}">
							{{data.label}}
						</nb-checkbox>
						<ul *ngFor="let sub_menu of data.sub_menu; let j=index" class="group-menulist">
							<li>
								<nb-checkbox (checkedChange)="toggle($event, data.menu_code, sub_menu.room_id)"
									[checked]="checkList[data.menu_code][sub_menu.room_id]"
									[ngStyle]="{'background-color':data.schedule_color}"
									class="sub{{sub_menu.room_id}}">
									{{sub_menu.room_name}}
								</nb-checkbox>
							</li>
						</ul>
					</div>
					<div *ngIf="data.menu_code != surgery_schedule">
						<nb-checkbox (checkedChange)="toggle($event, data.menu_code)"
							[checked]="checkList[data.menu_code]" [ngStyle]="{'background-color':data.schedule_color}"
							class="{{data.menu_code}}">
							{{data.label}}
						</nb-checkbox>
					</div>

				</div>
			</div>
			<div class="download-group">
				<p style="font-size: 14px;color:#c51c30;font-weight: 600;">TU Orthopaedics <span style="color:#3e95b5;">Schedule</span></p>
				<img class="download_2" src="../../assets/images/avvv.svg">
				<div>
					<a href="{{android}}" target="_blank">
						<img src="../../assets/images/Google-play-store-icon.png">
					</a>
				</div>
				<div>
					<a (click)="transform(ios)" target="_blank" style="cursor: pointer">
						<img src="../../assets/images/images.png">
					</a>
				</div>

			</div>
		</div>
		<div class="col_right">
			<div class="row text-left home_headCar">
				<div class="col-md-12 col-12">
					<div class="btn-group">

						<div class="home_today" mwlCalendarToday [(viewDate)]="viewDate">
							Today
						</div>
						<div mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
							(viewDateChange)="closeOpenMonthViewDay()">
							<img src="../../assets/images/next.png">
						</div>
						<div mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
							(viewDateChange)="closeOpenMonthViewDay()">
							<img src="../../assets/images/next1.png">
						</div>
						<div class="home_mounth">
							<h5>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h5>
						</div>
					</div>
				</div>
			</div>
			<br />
			<div [ngSwitch]="view" class="home_calendar">
				<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
					[refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
					(beforeViewRender)="beforeMonthViewRender($event)" [refresh]="refresh"
					[cellTemplate]="customCellTemplate" (eventClicked)="handleEvent('Clicked', $event.event)"
					(eventTimesChanged)="eventTimesChanged($event)" (dragEnter)="externalDragActive = true">
				</mwl-calendar-month-view>
			</div>
			<ng-template #customCellTemplate let-day="day" let-locale="locale">
				<div (click)="handleEvent('Clicked', day.events[0],day.date )">
					<div class="cal-cell-top text_d">
						{{ day.date | calendarDate:'monthViewDayNumber':locale }}
					</div>
					<div class="event_box">
						<div *ngFor="let e of day.events;index as i;">
							<div *ngIf="i < 7" class="event_dot" [ngStyle]="{'background-color':e.color.primary}"></div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</nb-layout-column>
	<router-outlet></router-outlet>
</nb-layout>
<div class="home_coptright">{{copyRight}}</div>