<nb-card class="dialog-card">
	<div *ngIf="action == 'list'">
		<nb-card-header class="dialog_head headBorder">
			<div class="dialog_t" (click)="today()">Today</div>
			<div class="dialog_p" (click)="previous()"><img src="../../assets/images/next.png"></div>
			<div class="dialog_p" (click)="next()"><img src="../../assets/images/next1.png"></div>
			<div class="dialog_m">
				<h5>{{ title }}</h5>
			</div>
			<div class="dialog_x" (click)="dismiss()"><img src="../../../assets/images/close.png"></div>
		</nb-card-header>
		<nb-card-body>
			<div *ngFor="let item of list" class="detail-body detail-body-list">
				<div class="row">
					<div class="col-3">
						<div class="dialog_date" *ngIf="checkDupDate(item.schedule_start_date,item.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':item.schedule_color}"></div>
							{{ checkFormatData(item.schedule_start_date) |date :'dd' }} - {{ checkFormatData(item.schedule_end_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date" *ngIf="!checkDupDate(item.schedule_start_date,item.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':item.schedule_color}"></div>
							{{ checkFormatData(item.schedule_start_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date1">{{ checkFormatData(item.schedule_start_datetime) |date :'HH:mm' }} -
							{{ checkFormatData(item.schedule_end_datetime) |date :'HH:mm'}}</div>
					</div>
					<div class="col-7" *ngIf="item.schedule_type== 'MN-001'">
						<p>{{item.staff_name}}</p>
						<p>OP : {{item.op| pluck}}</p>
					</div>
					<div class="col-7" *ngIf="item.schedule_type== 'MN-003'">
						<p>{{item.academic_type_name}}</p>
						<p>{{item.title}}</p>
						<p>{{item.presenter_1}}</p>
						<p>{{item.advisor_name}}</p>
					</div>
					<div class="col-7" *ngIf="item.schedule_type== 'MN-002'">
						<p>Type : {{item.service_type_name}}</p>
						<p>Chief R : {{item.chief_r}}</p>
						<p>{{item.orthopaedic_subspecialties_name}}</p>
					</div>
					<div class="col-7" *ngIf="item.schedule_type != 'MN-003' && item.schedule_type != 'MN-002'&& item.schedule_type != 'MN-001'">
						<p>{{item.activity_type_code_name}}</p>
						<p>{{item.title}}</p>
						<p>{{item.venue}}</p>
						<p>{{item.presenter_1}}</p>
					</div>
					<div class="col-1"><button nbButton status="primary" class="dialog_detail"
							(click)="mordetail(item)">More detail</button></div>
				</div>
			</div>
		</nb-card-body>
	</div>
	<div *ngIf="action == 'detail'">
		<nb-card-header style="display: flex;position: relative;" class="headBorder">
			<div class="dialog_t" (click)="backStep()">Back</div>
			<div class="dialog_m">
				<h5>{{ title }}</h5>
			</div>
			<div class="dialog_x" (click)="dismiss()"><img src="../../../assets/images/close.png"></div>
		</nb-card-header>
		<nb-card-body>
			<div class="detail-body">
				<div class="row" *ngIf="detailFetch.schedule_type == 'MN-001'">
					<div class="col-3">
						<div class="dialog_date" *ngIf="checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd' }} - {{ checkFormatData(detailFetch.schedule_end_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date" *ngIf="!checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date1">{{ checkFormatData(detailFetch.schedule_start_datetime) |date :'HH:mm' }} -
							{{ checkFormatData(detailFetch.schedule_end_datetime) |date :'HH:mm'}}</div>
					</div>
					<div class="col-9" style="line-height: 30px;">
						<div class="row">
							<div class="col-3">Surgery room</div>
							<div class="col-9">{{detailFetch.room_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Staff name</div>
							<div class="col-9">{{detailFetch.staff_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Patient Name</div>
							<div class="col-9">{{detailFetch.patient_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Age</div>
							<div class="col-9">{{detailFetch.age}}</div>
						</div>
						<div class="row">
							<div class="col-3">Phone</div>
							<div class="col-9">{{detailFetch.phone_number}}</div>
						</div>
						<div class="row">
							<div class="col-3">HN</div>
							<div class="col-9">{{detailFetch.hn}}</div>
						</div>
						<div class="row">
							<div class="col-3">Opertive room</div>
							<div class="col-9">{{detailFetch.operative_room_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Group</div>
							<div class="col-9">{{detailFetch.group_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Order</div>
							<div class="col-9">{{detailFetch.ordes_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Anesth</div>
							<div class="col-9">{{detailFetch.anesth_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">VIP</div>
							<div class="col-9">{{detailFetch.vip_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">OPD/IPD</div>
							<div class="col-9">{{detailFetch.opd_ipd}}</div>
						</div>
						<div class="row">
							<div class="col-3">DX</div>
							<div class="col-9">{{detailFetch.dx | pluck}}</div>
						</div>
						<div class="row">
							<div class="col-3">OP</div>
							<div class="col-9">{{detailFetch.op | pluck}}</div>
						</div>
						<div class="row">
							<div class="col-3">Implant</div>
							<div class="col-9">{{detailFetch.implant | pluck}}</div>
						</div>
						<div class="row">
							<div class="col-3">Remark</div>
							<div class="col-9">{{detailFetch.remark}}</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="detailFetch.schedule_type == 'MN-003'">
					<div class="col-3">
						<div class="dialog_date" *ngIf="checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd' }} - {{ checkFormatData(detailFetch.schedule_end_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date" *ngIf="!checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date1">{{ checkFormatData(detailFetch.schedule_start_datetime) |date :'HH:mm' }} -
							{{ checkFormatData(detailFetch.schedule_end_datetime) |date :'HH:mm'}}</div>
					</div>
					<div class="col-9" style="line-height: 30px;">
						<div class="row">
							<div class="col-3">Type</div>
							<div class="col-9">{{detailFetch.academic_type_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Title</div>
							<div class="col-9">{{detailFetch.title}}</div>
						</div>
						<div class="row">
							<div class="col-3">Advisor</div>
							<div class="col-9">{{detailFetch.advisor_code}}</div>
						</div>
						<div class="row">
							<div class="col-3">Praticipants 1</div>
							<div class="col-9">{{detailFetch.presenter_1}}</div>
						</div>
						<div class="row">
							<div class="col-3">Praticipants 2</div>
							<div class="col-9">{{detailFetch.presenter_2}}</div>
						</div>
						<div class="row">
							<div class="col-2">Remark</div>
							<div class="col-10">{{detailFetch.remark}}</div>
						</div>
					</div>
				</div>
				<div class="row"
					*ngIf="detailFetch.schedule_type != 'MN-003' && detailFetch.schedule_type != 'MN-002'&& detailFetch.schedule_type != 'MN-001'">
					<div class="col-3">
						<div class="dialog_date" *ngIf="checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd' }} - {{ checkFormatData(detailFetch.schedule_end_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date" *ngIf="!checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date1">{{ checkFormatData(detailFetch.schedule_start_datetime) |date :'HH:mm' }} -
							{{ checkFormatData(detailFetch.schedule_end_datetime) |date :'HH:mm'}}</div>
					</div>
					<div class="col-9" style="line-height: 30px;">
						<div class="row">
							<div class="col-3">Type</div>
							<div class="col-9">{{detailFetch.activity_type_code_name}}</div>
						</div>
						<div class="row">
							<div class="col-3">Title</div>
							<div class="col-9">{{detailFetch.title}}</div>
						</div>
						<div class="row">
							<div class="col-3">Venue</div>
							<div class="col-9">{{detailFetch.venue}}</div>
						</div>
						<div class="row">
							<div class="col-3">Praticipants 1</div>
							<div class="col-9">{{detailFetch.presenter_1}}</div>
						</div>
						<div class="row">
							<div class="col-3">Praticipants 2</div>
							<div class="col-9">{{detailFetch.presenter_2}}</div>
						</div>
						<div class="row">
							<div class="col-3">Remark</div>
							<div class="col-9">{{detailFetch.remark}}</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="detailFetch.schedule_type == 'MN-002'">
					<div class="col-3">
						<div class="dialog_date" *ngIf="checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd' }} - {{ checkFormatData(detailFetch.schedule_end_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date" *ngIf="!checkDupDate(detailFetch.schedule_start_date,detailFetch.schedule_end_date)">
							<div class="dialog_color" [ngStyle]="{'background-color':detailFetch.schedule_color}"></div>
							{{ checkFormatData(detailFetch.schedule_start_date) |date :'dd MMM'}}
						</div>
						<div class="dialog_date1">{{ checkFormatData(detailFetch.schedule_start_datetime) |date :'HH:mm' }} -
							{{ checkFormatData(detailFetch.schedule_end_datetime) |date :'HH:mm'}}</div>
					</div>
					<!-- <div class="col-9" style="line-height: 30px;" *ngIf="detailFetch.service_type_code==='SVT-001'">
						<div class="row">
							<div class="col-2">Chief R</div>
							<div class="col-10">{{detailFetch.chief_r}}</div>
						</div>
						<div class="row">
							<div class="col-2">Type</div>
							<div class="col-10">{{detailFetch.orthopaedic_subspecialties_name}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 4</div>
							<div class="col-10">{{detailFetch.r_4}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 3</div>
							<div class="col-10">{{detailFetch.r_3}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 2</div>
							<div class="col-10">{{detailFetch.r_2}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 1</div>
							<div class="col-10">{{detailFetch.r_1}}</div>
						</div>
						<div class="row">
							<div class="col-2">Intern</div>
							<div class="col-10">{{detailFetch.intern}}</div>
						</div>
					</div>
					<div class="col-9" style="line-height: 30px;" *ngIf="detailFetch.service_type_code==='SVT-002'">
						<div class="row">
							<div class="col-2">Staff name</div>
							<div class="col-10">{{detailFetch.staff_name}}</div>
						</div>
						<div class="row">
							<div class="col-2">Type</div>
							<div class="col-10">{{detailFetch.orthopaedic_subspecialties_name}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 4</div>
							<div class="col-10">{{detailFetch.r_4}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 3</div>
							<div class="col-10">{{detailFetch.r_3}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 2</div>
							<div class="col-10">{{detailFetch.r_2}}</div>
						</div>
						<div class="row">
							<div class="col-2">R 1</div>
							<div class="col-10">{{detailFetch.r_1}}</div>
						</div>
						<div class="row">
							<div class="col-2">Intern</div>
							<div class="col-10">{{detailFetch.intern}}</div>
						</div>
						<div class="row">
							<div class="col-2">ER Daytime</div>
							<div class="col-10">{{detailFetch.er_daytime}}</div>
						</div>
					</div> -->
					<div class="col-9" style="line-height: 30px;">
						<div class="row">
							<div class="col-2">Group</div>
							<div class="col-10">{{detailFetch.groups_name}}</div>
						</div>
						<div class="row">
							<div class="col-2">OR No.</div>
							<div class="col-10">{{detailFetch.or_name}}</div>
						</div>
						<div class="row">
							<div class="col-2">Staff name</div>
							<div class="col-10">{{detailFetch.staff_name}}</div>
						</div>
						<div class="row">
							<div class="col-2">Resident</div>
							<div class="col-10">{{detailFetch.resident}}</div>
						</div>
						<div class="row">
							<div class="col-2">Intern</div>
							<div class="col-10">{{detailFetch.intern}}</div>
						</div>
					</div>
				</div>
			</div>
		</nb-card-body>
	</div>
</nb-card>