import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service'

import { NbToastrService, NbComponentStatus } from "@nebular/theme";

@Component({
	selector: 'ngx-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: ApiService,
		private toastrService: NbToastrService
	) {

	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});

		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}

	transform(){
		window.open('itms-services://?action=download-manifest&url=https://api.tuorca.com/app_download/ios.plist','_blank');
	}

	showToast(message: string, title: string, status: NbComponentStatus) {
		this.toastrService.show(
			message,
			title,
			{ status }
		);
	}

	// convenience getter for easy access to form fields
	get f() { return this.loginForm.controls; }

	onSubmit() {
		this.submitted = true;

		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}

		this.loading = true;
		this.authenticationService.loginAsMember(this.f.username.value, this.f.password.value)
			.subscribe(
				data => {
					this.loading = false;
					if (data.data) {
						this.authenticationService.setStorage('ac', data.data.access_token);
						this.router.navigate(['home']);
					} else {
						this.showToast(data.message, 'เกิดข้อผิดพลาด', 'warning');
					}
				},
				error => {
					console.log(error);
					this.showToast(error.message, 'เกิดข้อผิดพลาด', 'danger');
					this.loading = false;
				});
	}
}
