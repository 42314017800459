import {
	OnInit,
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	TemplateRef,
} from '@angular/core';
import {
	startOfDay,
	endOfDay,
	subDays,
	addDays,
	endOfMonth,
	isSameDay,
	isSameMonth,
	addHours,
	format,
	formatISO,
	parseISO
} from 'date-fns';
import { Subject } from 'rxjs';

import * as moment from 'moment';
import {
	CalendarEvent,
	CalendarEventAction,
	CalendarEventTimesChangedEvent,
	CalendarDateFormatter,
	CalendarMonthViewBeforeRenderEvent,
	CalendarView,
} from 'angular-calendar';
import {DomSanitizer} from '@angular/platform-browser';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { NbDialogService, NbMenuService } from '@nebular/theme';
import { ApiService } from '../api.service'
import * as queryString from 'query-string';
import { filter, map } from 'rxjs/operators';
import { DialogComponent } from './dialog.component/dialog.component'
import { CustomDateFormatter } from '../providers/CustomDateFormatter.provider';
const colors: any = {
	red: {
		primary: '#ad2121',
		secondary: '#FAE3E3',
	},
	blue: {
		primary: '#1e90ff',
		secondary: '#D1E8FF',
	},
	yellow: {
		primary: '#e3bc08',
		secondary: '#FDF1BA',
	},
};

interface sub_menuInterface {
	room_id: string,
	room_name: string,
	update_at: string,
	statusCode: string,
}

interface menuListInterface {
	menu_code: string,
	label: string,
	color: string,
	sub_menu: sub_menuInterface[]
}

interface scheduleCalendarInterface {
	surgery_id: string,
	schedule_start_datetime: string,
	schedule_start_date: string,
	schedule_start_time: string,
	schedule_end_datetime: string,
	schedule_end_date: string,
	schedule_end_time: string,
	schedule_type: string,
	schedule_color: string
}
interface scheduleListInterface {
	surgery_id: string,
	id: string,
	account_id: string,
	room_id: string,
	schedule_start_date: string,
	schedule_start_time: string,
	schedule_start_datetime: string,
	schedule_end_date: string,
	schedule_end_time: string,
	schedule_end_datetime: string,
	staff_code: string,
	patient_name: string,
	age: string,
	phone_number: string,
	hn: string,
	operative_room_code: string,
	group_code: string,
	ordes_code: string,
	duration: string,
	anesth_code: string,
	dx: [string],
	op: [string],
	implant: [string],
	vip_code: string,
	opd_ipd: string,
	remark: string,
	status_code: string,
	create_at: string,
	update_at: string,
	staff_name: string,
	operative_room_name: string,
	group_name: string,
	group_color: string,
	ordes_name: string,
	anesth_name: string,
	vip_name: string,
	duration_name: string,
	schedule_type: string,
	schedule_color: string
}

@Component({
	selector: 'ngx-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	styles: [
		`
		  .cal-month-view .bg-pink,
		  .cal-week-view .cal-day-columns .bg-pink,
		  .cal-day-view .bg-pink {
			background-color: red !important;
		  }
		`,
	],
	providers: [
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatter,
		},
	],
})
export class HomeComponent implements OnInit {
	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

	view: CalendarView = CalendarView.Month;

	CalendarView = CalendarView;

	viewDate: Date = new Date();

	modalData: {
		action: string;
		event: CalendarEvent;
	};

	actions: CalendarEventAction[] = [
		{
			label: '<i class="fa fa-fw fa-pencil"></i>',
			a11yLabel: 'Edit',
			onClick: ({ event }: { event: CalendarEvent }, id: string = ""): void => {
				this.handleEvent('Edited', event, event.start);
			},
		},
		{
			label: '<i class="fa fa-fw fa-times"></i>',
			a11yLabel: 'Delete',
			onClick: ({ event }: { event: CalendarEvent }, id: string = ""): void => {
				this.events = this.events.filter((iEvent) => iEvent !== event);
				this.handleEvent('Deleted', event, event.start);
			},
		},
	];

	refresh: Subject<any> = new Subject();
	statusColors = [`warning`, `danger`, `primary`, `success`, `info`, `info`, `info`, `info`, `info`, `info`]
	events: CalendarEvent[] = [];
	scheduleCarendar: scheduleCalendarInterface[] = [];
	scheduleList: scheduleListInterface[] = [];
	checkList: any = [];
	menuList: menuListInterface[] = []; // menu on sidebar
	activeDayIsOpen: boolean = false;
	// fixes value for send parameters
	surgery_schedule: string = "MN-001";
	service_schedule: string = "MN-002";
	academic_schedule: string = "MN-003";
	activity_schedule: string = "MN-004";
	my_schedule: string = "MN-005";
	current_parameter: any;
	profile: any = {};

	items = [{
		id: 'logout',
		icon: 'unlock-outline',
		title: 'Logout'
	}];
	picture: string = "";
	name: string = "";
	title: string = "";

	// download and copy right
	ios: string = "";
	android: string = "";
	copyRight: string = "";
	statusInfo = false;
	colorTemp;
	public codeTemp;
	constructor(
		private nbMenuService: NbMenuService,
		private dialogService: NbDialogService,
		private authenticationService: ApiService,
		private fb: FormBuilder,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.prepareVariables();
		this.fetchProfile();
		this.downloadComponent();
		this.nbMenuService.onItemClick()
			.pipe(
				filter(({ tag }) => tag === 'my-context-menu'),
				map(({ item }) => item),
			)
			.subscribe(item => {
				if (item['id'] == 'logout') {
					this.authenticationService.logout();
				}
				else if (item['id'] == 'profile') {
					console.log(item);
				}
			});
	}

	transform(value: any,): any {
		window.open(value,'_blank');
	  }

	async prepareVariables() {
		await this.authenticationService.loadMenuList().subscribe(
			data => {
				if (data['statusCode'] == 200) {
					this.menuList = data.data
					this.setupDefaultCheckbox(this.menuList)
					this.loadCalendar('calendar');
					try {
						setTimeout(() => {
							let d = this.menuList;
							for (let index = 0; index < d.length; index++) {
								let color = d[index].color;
								var elements = document.getElementsByClassName(d[index]['menu_code']); // get all elements
								let el = <HTMLElement>document.querySelector('.' + d[index]['menu_code'] + ' .custom-checkbox.checked');
								if (d[index].sub_menu.length != 0) {
									for (let i = 0; i < d[index].sub_menu.length; i++) {
										let ell = <HTMLElement>document.querySelector('.sub' + d[index].sub_menu[i].room_id + ' .custom-checkbox.checked');
										if (ell != null) {
											ell.style.borderColor = color;
											ell.style.backgroundColor = color;
										}
									}
								}
								if (el != null) {
									el.style.borderColor = color;
									if (d[index]['menu_code'] == 'MN-005') {
										this.colorTemp = color
									} else {
										el.style.backgroundColor = color;
									}
								}
								//el.style.borderColor = color;
							}
						}, 300);
						setTimeout(() => {
							var e = <HTMLElement>document.querySelector('#hoomeside');
							e.style.display = 'block';
						}, 500);

					} catch (error) {

					}

				}
			},
			error => {
				console.log(error);
			});
		return;
	}
	expandInfo() {
		this.statusInfo = !this.statusInfo;
	}
	logout() {
		this.authenticationService.logout();
	}
	setupDefaultCheckbox(object: menuListInterface[]): void {
		for (var key in object) {
			if (object.hasOwnProperty(key)) {
				var element = object[key];
				if (element.menu_code == this.surgery_schedule) {
					this.checkList[element.menu_code] = []
					for (var key in element.sub_menu) {
						if (element.sub_menu.hasOwnProperty(key)) {
							var elem = element.sub_menu[key];
							this.checkList[element.menu_code][elem.room_id] = true;
						}
					}
				} else {
					this.checkList[element.menu_code] = true;
				}
				if (element.menu_code == 'MN-005') {
					this.checkList[element.menu_code] = false;
				}
			}
		}
	}
	beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
		renderEvent.body.forEach((day) => {
			day.cssClass = 'bg-pink';
		});
	}
	downloadComponent(): void {
		this.authenticationService.getDownloadUrl().subscribe(
			data => {
				if (data['statusCode'] == 200) {
					const result = data.data
					this.ios = result.ios_download_url;
					this.android = result.android_download_url;
					this.copyRight = result.copy_right;
				}
			},
			error => {
				console.log(error);
			});
	}

	toggle(checked: boolean, main_code: string, room_id: string = "") {

		if (room_id == "") {
			if (main_code == this.surgery_schedule) {
				const object = this.checkList[main_code]
				for (const key in object) {
					if (object.hasOwnProperty(key)) {
						this.checkList[main_code][key] = checked;
					}
				}
			} else {
				this.checkList[main_code] = checked
			}

		} else {
			this.checkList[main_code][room_id] = checked;
		}
		if (main_code === 'MN-005' && checked) {
			let el = <HTMLElement>document.querySelector('.MN-005 .custom-checkbox');
			el.style.backgroundColor = this.colorTemp;
			const object = this.checkList
			for (const key in object) {
				if (key != 'MN-005' && key != 'MN-001') {
					this.checkList[key] = false;
				} else if (key == 'MN-001') {
					let d = this.checkList['MN-001']
					for (const k in d) {
						this.checkList['MN-001'][k] = false;
					}
				}
			}
			this.checkList[main_code] = checked
			this.loadMyShedule('calendar');
		} else {
			this.checkList['MN-005'] = false
			this.loadCalendar('calendar');
		}


	}

	onImgError(event) { 
		this.picture = '../../../../assets/images/profile_defualt.png';
	}
	loadMyShedule(type: string): void {
		var d = new Date(this.viewDate);
		var m = d.getMonth(); //current month
		var y = d.getFullYear(); //current year
		var firstDay = new Date(y, m, 1 + 1).toISOString().slice(0, 10);
		// var firstDay = '2020-03-01' // fix for test
		var lastDay = new Date(y, m + 1, 0 + 1).toISOString().slice(0, 10);


		// prepare variables
		var parameters = {
			start_date: firstDay,
			end_date: lastDay,
			action_type: type,
			code: 'MN-005'
		}
		this.codeTemp = 'MN-005';
		this.current_parameter = parameters;
		var param = queryString.stringify(parameters)
		this.authenticationService.loadMySchedule(param).subscribe(
			data => {
				if (data['statusCode'] == 200) {
					if (type == 'calendar') {
						this.scheduleCarendar = data.data
						this.setupEventMySchedule(data.data)
					} else {
						this.scheduleList = data.data
					}
				} else {
					this.scheduleList = [];
					this.events = [];
				}
			},
			error => {
				console.log(error);
			});
	}
	loadCalendar(type: string): void {
		var d = new Date(this.viewDate);
		var m = d.getMonth(); //current month
		var y = d.getFullYear(); //current year
		var firstDay = new Date(y, m, 1 + 1).toISOString().slice(0, 10);
		// var firstDay = '2020-03-01' // fix for test
		var lastDay = new Date(y, m + 1, 0 + 1).toISOString().slice(0, 10);

		// setup room
		const room = []
		var roomString = ""
		if (this.checkList[this.surgery_schedule]) {
			const object = this.checkList[this.surgery_schedule]
			for (const key in object) {
				if (object.hasOwnProperty(key)) {
					const element = object[key];
					if (element === true) {
						room.push(key)
					}
				}
			}
			roomString = room.join(',')
		}


		// prepare variables
		var parameters = {
			surgery_room: `[${roomString}]`,
			service_schedule: (this.checkList[this.service_schedule]) ? this.service_schedule : "",
			academic_schedule: (this.checkList[this.academic_schedule]) ? this.academic_schedule : "",
			activity_schedule: (this.checkList[this.activity_schedule]) ? this.activity_schedule : "",
			start_date: firstDay,
			end_date: lastDay,
			action_type: type,
			code: 'MN-00'
		}
		this.codeTemp = 'MN-00';
		this.current_parameter = parameters;
		var param = queryString.stringify(parameters)
		this.authenticationService.loadScheduleList(param).subscribe(
			data => {
				if (data['statusCode'] == 200) {
					if (type == 'calendar') {
						this.scheduleCarendar = data.data
						this.setupEvent(data.data)
					} else {
						this.scheduleList = data.data
					}
				} else {
					this.scheduleList = [];
					this.events = [];
				}
			},
			error => {
				console.log(error);
			});
	}

	setupEvent(object: scheduleCalendarInterface) {
		try {
			const result: CalendarEvent[] = [];
			for (const key in object) {
				if (object.hasOwnProperty(key)) {
					const element = object[key];
					// element.surgery_id
					const json = {
						start:moment(element.schedule_start_datetime, 'YYYY-MM-DD HH:mm').toDate(), 
						end: moment(element.schedule_end_datetime, 'YYYY-MM-DD HH:mm').toDate(),
						title: new Date(element.schedule_start_datetime).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
						surgery_id: element.surgery_id,
						color: {
							primary: element.schedule_color,
							secondary: element.schedule_color,
						},
						actions: this.actions,
					}
					result.push(json)
				}
			}
			this.events = result
		} catch (error) {
			console.log(error);
		}

	}
	setupEventMySchedule(object: scheduleCalendarInterface) {
		const result: CalendarEvent[] = [];
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				const element = object[key];
				// element.surgery_id
				const json = {
					start: new Date(element.schedule_start_date),
					end: new Date(element.schedule_end_date),
					title: new Date(element.schedule_start_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
					surgery_id: element.surgery_id,
					color: {
						primary: element.schedule_color,
						secondary: element.schedule_color,
					},
					actions: this.actions,
				}
				result.push(json)
			}
		}
		this.events = result

	}

	fetchProfile(): void {
		this.authenticationService.getProfile().subscribe(
			data => {
				try {
					if (data['statusCode'] == 200) {
						this.profile = data.data[0]
						this.picture = this.profile.image_profile
						this.name = `${this.profile.fname} ${this.profile.lname}`
						this.title = this.profile.email

						this.items = [
							{
								id: 'fullname',
								icon: 'person-outline',
								title: `${this.profile.fname} ${this.profile.lname} <br> ${this.profile.work_position}`
							},
							{
								id: 'phone',
								icon: 'email-outline',
								title: this.profile.email
							},
							{
								id: 'phone',
								icon: 'phone-outline',
								title: this.profile.phone
							},
							{
								id: 'fax',
								icon: 'person-outline',
								title: this.profile.fax
							},
							{
								id: 'logout',
								icon: 'unlock-outline',
								title: 'Logout'
							},
						];
					}
				} catch (error) {
					console.log(error);
				}

			},
			error => {
				console.log(error);
			});
		return;
	}

	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
			if (
				(isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
				events.length === 0
			) {
				this.activeDayIsOpen = false;
			} else {
				this.activeDayIsOpen = false;
			}
			this.viewDate = date;
		}
	}

	handleEvent(action: string, event: CalendarEvent, start): void {
		try {
			if (event != null) {
				this.statusInfo = false;
				let dialogRef = this.dialogService.open(DialogComponent)
				dialogRef.componentRef.instance.title = event.title;
				dialogRef.componentRef.instance.action = "list";
				dialogRef.componentRef.instance.current_parameter = this.current_parameter;
				dialogRef.componentRef.instance.loadList(start, this.codeTemp)
			}

		} catch (error) {

		}

	}

	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		if (this.codeTemp == 'MN-005') {
			this.loadMyShedule('calendar');
		} else {
			this.loadCalendar('calendar');
		}

		this.activeDayIsOpen = false;
	}
}
