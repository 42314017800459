import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
	NbAuthComponent,
	NbLoginComponent,
	NbLogoutComponent,
	NbRegisterComponent,
	NbRequestPasswordComponent,
	NbResetPasswordComponent,
} from '@nebular/auth';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
	{
		path: 'pages',
		loadChildren: () => import('./pages/pages.module')
			.then(m => m.PagesModule),
	},
	{ path: 'home', component: HomeComponent },
	{ path: 'login', component: LoginComponent },
	{
		path: 'auth',
		component: NbAuthComponent,
		children: [
			{
				path: '',
				component: NbLoginComponent,
			},
			{
				path: 'login',
				component: NbLoginComponent,
			},
			{
				path: 'register',
				component: NbRegisterComponent,
			},
			{
				path: 'logout',
				component: NbLogoutComponent,
			},
			{
				path: 'request-password',
				component: NbRequestPasswordComponent,
			},
			{
				path: 'reset-password',
				component: NbResetPasswordComponent,
			},
		],
	},
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: '**', redirectTo: 'login' },
];

const config: ExtraOptions = {
	useHash: false,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
