import { catchError, retry } from 'rxjs/internal/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import * as queryString from 'query-string';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { environment } from '../environments/environment';

const apiUrl = environment.api.base_url;
const loginUrl = apiUrl + 'login'
const httpOptions = {
	headers: new HttpHeaders({
		'ZIGMANICE-API-KEY': environment.api.api_key,
		'Content-Type': 'application/x-www-form-urlencoded'
	})
};

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(
		private http: HttpClient,
		private router: Router
	) { }

	loginAsMember(username: string, password: string): any {
		const params = {
			username: username,
			password: password,
			login_type: 'member'
		}
		return this.http.post<any>(loginUrl, queryString.stringify(params), httpOptions)
			.pipe(
				catchError(this.handleError('login'))
			);
	}

	logout(): void {
		this.clearStorage();
		this.router.navigate(['login']);
	}

	loadMenuList(): any {
		return this.http.post<any>(apiUrl + 'menu/list', {}, httpOptions)
			.pipe(
				catchError(this.handleError('loadMenuList'))
			);
	}
	// schedule/list 
	loadScheduleList(params): any {
		return this.http.post<any>(apiUrl + 'schedule/list', params, httpOptions)
			.pipe(
				catchError(this.handleError('scheduleList'))
			);
	}
	loadMyScheduleList(params): any {
		const token = this.getAccesToken();
		const options = {
			headers: new HttpHeaders({
				'ZIGMANICE-API-KEY': environment.api.api_key,
				'Content-Type': 'application/x-www-form-urlencoded',
				'ZIGMANICE-AUTH': token,
			})
		};
		return this.http.post<any>(apiUrl + 'my_schedule', params, options)
			.pipe(
				catchError(this.handleError('my_scheduleList'))
			);
	}
	loadMySchedule(params): any {
		const token = this.getAccesToken();
		const options = {
			headers: new HttpHeaders({
				'ZIGMANICE-API-KEY': environment.api.api_key,
				'Content-Type': 'application/x-www-form-urlencoded',
				'ZIGMANICE-AUTH': token,
			})
		};
		return this.http.post<any>(apiUrl + 'my_schedule', params, options)
			.pipe(
				catchError(this.handleError('loadMySchedule'))
			);
	}
	getDownloadUrl(): any {
		return this.http.post<any>(apiUrl + 'application/url', {}, httpOptions)
			.pipe(
				catchError(this.handleError('application/url'))
			);
	}

	getProfile(): any {
		try {
			const token = this.getAccesToken();
			const options = {
				headers: new HttpHeaders({
					'ZIGMANICE-API-KEY': environment.api.api_key,
					'Content-Type': 'application/x-www-form-urlencoded',
					'ZIGMANICE-AUTH': token,
				})
			};
			return this.http.post<any>(apiUrl + 'profile', {}, options)
				.pipe(
					catchError(this.handleError('profile'))
				);
		} catch (error) {
			
		}

	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.error(error);
			this.log(`${operation} failed: ${error.message}`);

			return of(result as T);
		};
	}

	private log(message: string) {
		console.log(message);
	}

	setStorage(key: string, value: string): void {
		localStorage.setItem(key, value);
	}
	getAccesToken(): string {
		return localStorage.getItem('ac');
	}
	clearStorage(): void {
		localStorage.clear();
	}
}